import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { getFilterQueryCriteriaFromUrl } from '../../utils/filter';

const FACTOID_ORG_ID = 'factoids.organization_id.keyword';
const FACTOID_ORG_ID_PARAM = 'factoids.organizationId.keyword-n';
const FACTOID_ORG_NAME = 'factoids.organization_name.keyword';
const FACTOID_TYPE = 'factoids.type.keyword';
const FACTOID_TYPE_PARAM = 'factoids.type.keyword-n';
const FACTOID_SOURCE = 'factoids.source.keyword';
const FACTOID_SOURCE_PARAM = 'factoids.source.keyword-n';

const omits: string[] = [FACTOID_ORG_ID_PARAM, FACTOID_TYPE_PARAM, FACTOID_SOURCE_PARAM];

export const FACTOID_AGGREGATE_QUERY = gql`
  query FactoidAggregateBuckets($filter: JSONObject!) {
    result: viewPeopleLists(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useOrganizationsForFactoids = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria: any = getFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', omits);

  queryCriteria.filter.aggs = {
    agg_custom_field: {
      nested: {
        path: 'factoids'
      }
    }
  };

  const aggs = {
    agg_value: {
      multi_terms: {
        terms: [
          {
            field: FACTOID_ORG_NAME
          },
          {
            field: FACTOID_ORG_ID
          }
        ],
        size: 9999
      },
      aggs: {
        top_reverse_nested: {
          reverse_nested: {}
        }
      }
    }
  };

  if (searchParams.has(`${FACTOID_TYPE_PARAM}`)) {
    const values = searchParams.getAll(`${FACTOID_TYPE_PARAM}`);
    queryCriteria.filter.aggs.agg_custom_field.aggs = {
      root_filter: {
        filter: {
          bool: {
            should: [],
            minimum_should_match: 1
          }
        },
        aggs: aggs
      }
    }
    values.forEach(v => {
      queryCriteria.filter.aggs.agg_custom_field.aggs.root_filter.filter.bool.should.push({
        match: {
          "factoids.type.keyword": v
        }
      });
    });
  }
  else {
    queryCriteria.filter.aggs.agg_custom_field.aggs = aggs;
  }

  queryCriteria.filter.size = 0;

  const vars = { variables: { ...queryCriteria }, context: { version: '2' } };

  return useLazyQuery(FACTOID_AGGREGATE_QUERY, vars);
};

export const useTypesForFactoids = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria: any = getFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', omits);

  queryCriteria.filter.aggs = {
    agg_custom_field: {
      nested: {
        path: 'factoids'
      }
    }
  };

  const aggs = {
    agg_value: {
      terms: {
          field: FACTOID_TYPE,
          size: 9999
      },
      aggs: {
        top_reverse_nested: {
          reverse_nested: {}
        }
      }
    }
  };

  if (searchParams.has(`${FACTOID_ORG_ID_PARAM}`)) {
    const values = searchParams.getAll(`${FACTOID_ORG_ID_PARAM}`);
    queryCriteria.filter.aggs.agg_custom_field.aggs = {
      root_filter: {
        filter: {
          bool: {
            should: [],
            minimum_should_match: 1
          }
        },
        aggs: aggs
      }
    }
    values.forEach(v => {
      queryCriteria.filter.aggs.agg_custom_field.aggs.root_filter.filter.bool.should.push({
        match: {
          "factoids.organization_id.keyword": v
        }
      });
    });
  }
  else {
    queryCriteria.filter.aggs.agg_custom_field.aggs = aggs;
  }
  
  queryCriteria.filter.size = 0;

  const vars = { variables: { ...queryCriteria }, context: { version: '2' } };

  return useLazyQuery(FACTOID_AGGREGATE_QUERY, vars);
};


export const useSourcesForFactoids = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria: any = getFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch', omits);

  queryCriteria.filter.aggs = {
    agg_custom_field: {
      nested: {
        path: 'factoids'
      }
    }
  };

  const aggs = {
    agg_value: {
      terms: {
          field: FACTOID_SOURCE,
          size: 9999
      },
      aggs: {
        top_reverse_nested: {
          reverse_nested: {}
        }
      }
    }
  };

  if (searchParams.has(`${FACTOID_TYPE_PARAM}`)) {
    const values = searchParams.getAll(`${FACTOID_TYPE_PARAM}`);
    queryCriteria.filter.aggs.agg_custom_field.aggs = {
      root_filter: {
        filter: {
          bool: {
            should: [],
            minimum_should_match: 1
          }
        },
        aggs: aggs
      }
    }
    values.forEach(v => {
      queryCriteria.filter.aggs.agg_custom_field.aggs.root_filter.filter.bool.should.push({
        match: {
          "factoids.type.keyword": v
        }
      });
    });
  }
  else {
    queryCriteria.filter.aggs.agg_custom_field.aggs = aggs;
  }
  
  queryCriteria.filter.size = 0;

  const vars = { variables: { ...queryCriteria }, context: { version: '2' } };

  return useLazyQuery(FACTOID_AGGREGATE_QUERY, vars);
};


export const useFactoidTypesForOrganization = (organizationId: string) => {

  const queryCriteria: any = {
    filter: {
      aggs: {
        agg_custom_field: {
          nested: {
            path: 'factoids'
          },
          aggs: {
            root_filter: {
              filter: {
                bool: {
                  should: [{
                    match: {
                      "factoids.organization_id.keyword": organizationId
                    }
                  }],
                  minimum_should_match: 1
                }
              },
              aggs: {
                agg_value: {
                  terms: {
                      field: FACTOID_TYPE,
                      size: 9999
                  }
                }
              }
            }
          }
        }
      },
      size: 0
    }
  };

  const vars = { variables: { ...queryCriteria }, context: { version: '2' } };

  return useQuery(FACTOID_AGGREGATE_QUERY, vars);
};

