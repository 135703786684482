import { toLower } from 'lodash';
import { isNestedPath, toSnakeCase } from '../../utils/format';
import { stripPunctuation } from '../../utils/search';

export const nestedDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('nestedDbFilter is not supported');
};
export const nestedEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const path = field.split('.')[0];
  const snkField = toSnakeCase(field);

  return {
    nested: {
      path: path,
      query: {
        bool: {
          must: [
            { 
              match: { [snkField]: filterValue[0]}
            }
          ]
        }
      }
    }
  }
};
