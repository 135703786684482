import { capitalize } from 'lodash';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Option from '../../common/Option';
import { ClassNameProps } from '../../common/Props';
import { useSourcesForFactoids } from '../../gql/factoid';
import { useAddFilterIndicator } from '../../store/filter';
import { mergeRecords, useGeoOptionFormatter } from '../../utils/filter';
import { getSearchParamsAsOptions, useSelectSearch } from '../../utils/search';
import { factoidTypeMapper } from '../FilterIndicator/mappers';
import { FormSelect } from '../Form';

const FactoidSourceSelect = styled(
  memo(({ className }: {} & ClassNameProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const PARAM_KEY = 'factoids.source.keyword-n';

    const [value, setValue] = useState<Option[]>();

    const optionFormatter = useGeoOptionFormatter(false);

    const [getSources, { data, loading }] = useSourcesForFactoids();

    useEffect(() => {
      getSources();
    }, [getSources, searchParams]);

    useEffect(() => {
      if (data && !loading) {
        if (searchParams.has(PARAM_KEY)) {
          const sources =
            data?.result?.esResult?.aggregations?.agg_custom_field?.agg_value?.buckets ||
            data?.result?.esResult?.aggregations?.agg_custom_field?.root_filter?.agg_value?.buckets ||
            [];
          const vals = searchParams.getAll(PARAM_KEY);
          vals.forEach((v) => {
            const existing = sources.find((f: any) => f.key === v);
            if (!existing) {
              // @ts-ignore: expecting only 1 arg
              searchParams.delete(PARAM_KEY, v);
            }
          });
          setSearchParams(searchParams);
        }
      }
    }, [data]);

    const options = useMemo(() => {
      const sources =
        data?.result?.esResult?.aggregations?.agg_custom_field?.agg_value?.buckets ||
        data?.result?.esResult?.aggregations?.agg_custom_field?.root_filter?.agg_value?.buckets ||
        [];
      sources.forEach((type) => {
        type.label = capitalize(type.key.split('.')[0].split(/(?:[-_])/).join(' '));
      });

      return mergeRecords(
        sources,
        (c: any) => c.label || '',
        (c: any) => c.key,
        (c: any) => c.top_reverse_nested.doc_count
      );
    }, [data]);

    useEffect(() => {
      if (options && !loading) {
        setValue(getSearchParamsAsOptions(searchParams, PARAM_KEY, options as any));
      }
    }, [options, loading, setValue, searchParams]);

    const handleChange = useSelectSearch(PARAM_KEY, []);

    useAddFilterIndicator({
      [PARAM_KEY]: {
        paramKey: PARAM_KEY,
        label: 'has',
        nameMapper: factoidTypeMapper
      }
    });

    return (
      <div className={className}>
        {loading ? (
          <FormSelect placeholder="Associated Sources loading..." />
        ) : (
          <FormSelect
            placeholder="Associated Sources..."
            formatOptionLabel={optionFormatter}
            isLoading={loading}
            onChange={handleChange}
            portalTarget={document.querySelector(`.${className}`)}
            isClearable
            isMulti={true}
            value={value}
            options={options}
            className={` type-select`}
          />
        )}
      </div>
    );
  })
)`
  position: relative;
  margin-bottom: 8px;
  .form-select__menu {
    transform: translateY(-7px);
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    background-color: var(--primary-light);
    overflow: hidden;
    .form-select__option {
      border-bottom: 1px solid var(--filter-bg);
      color: var(--gray-light);
    }
  }
`;

export default FactoidSourceSelect;
