import { isNil, capitalize } from 'lodash';
import ReactGA from 'react-ga4';
import { formatAddress } from './format';

export const downloadCsv = (headers, data, entity, count?:number) => {
  const csv = arrayToCsv(headers, data);

  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
  element.setAttribute('download', `Roseland Download ${count||''} -  ${new Date().toISOString()}.csv`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

  // Send a custom event
  ReactGA.event({
    category: entity,
    action: 'Download'
  });
};

// Function to convert the JSON(Array of objects) to CSV.
export const arrayToCsv = (headers, data) => {
  const csvRows: string[] = [];

  // getting headers.
  const headerValues = headers.map((header) => escapeAndWrapQuotes(header.label || header.title));
  csvRows.push(headerValues.join(',')); // Push into array as comma separated values
  // Getting rows.
  for (const row of data) {
    const rowValues = headers.map((header) => {
      let data: any = null;
      if (!isNil(header.csvRenderer)) {
        data = header.csvRenderer({ rowData: row });
      } else {
        data =
          !isNil(header.dataKey) || isNil(header.dataGetter)
            ? extractDataFromRow(row, header.dataKey || header.key)
            : header.dataGetter({ rowData: row });
      }
      return formatText(data, header.format || 'none');
    });
    csvRows.push(rowValues.join(',')); // Push into array as comma separated values.
  }
  return csvRows.join('\n'); // To enter the next rows in the new line '\n'
};

export const formatText = (str, format) => {
  if (!str || 'null' === str || 'undefined' === str) {
    return '';
  }

  switch (format) {
    case 'address':
      str = formatAddress(str);
      break;
    case 'date':
      str = formatDate(str);
      break;
    case 'boolean':
      str = str ? 'true' : 'false';
      break;
    case 'none':
      break;
    default:
      str = capitalize(str);
  }

  return escapeAndWrapQuotes(str);
};

const escapeAndWrapQuotes = (str) => {
  return '"' + ('' + str).replace(/"/g, '""') + '"';
};

const formatDate = (str) => {
  return str.split('T')[0];
};

export const extractDataFromRow = (row: any, key: string) => {
  let data = row[key];
  if (key.indexOf('.') > -1) {
    const conn = key.split('.')[0];
    const field = key.split('.')[1];
    const node = row[conn]?.nodes;
    if (node && node.length > 0) {
      data = node.map((n) => n[field]).join('\n');
    }
  }
  return data;
};
