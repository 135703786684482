import { faBell } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import { env } from '../../env';
import { setResetPendingUsersIndicator, useResetPendingUsersIndicator } from '../../store/app';
import { ADMIN_ROLE, getJWTToken, hasRole } from '../../utils/auth';
import A from '../A';
import Icon from '../Icon';
import { paths } from '../routes/routes';

const PendingUsers: React.FC<ClassNameProps> = styled(({ className }) => {
  const [pendingUsers, setPendingUsers] = useState<number>(0);
  const resetPendingUsers = useResetPendingUsersIndicator();
  const timerRef = useRef();
  const loadPending = useCallback(() => {
    const url = `${env.REACT_APP_XHR_ROOT_URL}user/list?attributes.approved=false`;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getJWTToken()}`
      }
    })
      .then((r) => r.json())
      .then((result) => {
        if (!isNil(result)) {
          setPendingUsers(result.users.length || 0);
        }
      })
      .catch((error) => console.log('error', error));

    return setTimeout(loadPending, 60000);
  }, [setPendingUsers]);

  useEffect(() => {
    if (hasRole(ADMIN_ROLE)) {
      if (resetPendingUsers) {
        clearTimeout(timerRef.current);
        timerRef.current = loadPending();

        setResetPendingUsersIndicator(false);
      }
      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }, [loadPending, resetPendingUsers, timerRef]);

  return pendingUsers < 1 ? null : (
    <A
      to={`${paths.userApproval}?pending=true`}
      className={`pending-users ${className}`}
      title="Users Pending Approval"
    >
      <span className="pending-users__count">{pendingUsers > 99 ? '99' : pendingUsers}</span>
      <Icon icon={faBell} />
    </A>
  );
})`
  @media (max-width: 767.98px) {
    display: none;
  }
  @keyframes shake {
    0% {
      transform: rotate(-10deg);
    }
    1% {
      transform: rotate(0deg);
    }
    2% {
      transform: rotate(10deg);
    }
    3% {
      transform: rotate(-10deg);
    }
    4% {
      transform: rotate(0deg);
    }
    5% {
      transform: rotate(10deg);
    }
    6% {
      transform: rotate(0deg);
    }
  }
  &.pending-users {
    color: var(--danger);
    position: relative;
    font-size: 18px;
    // left: 0;

    right: 30px;
    align-self: center;

    animation: shake 5s infinite;
    .pending-users__count {
      color: var(--white);
      position: absolute;
      left: 50%;
      top: 46%;
      transform: translate(-50%, -50%);
      font-size: 10px;
    }
  }
`;

export default PendingUsers;
