import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ClassNameProps } from '../../common/Props';
import { setFilterState, setShowFilter, useFilterState, useShowFilter } from '../../store/filter';
import FilterTitle from './FilterTitle';
import FilterType from './FilterType';
import FactoidOrganizationSelect from './FactoidOrganizationSelect';
import FactoidTypeSelect from './FactoidTypeSelect';
import FactoidSourceSelect from './FactoidSourceSelect';

const Filter = styled(({ className, type }: { type: string } & ClassNameProps) => {
  const state = useFilterState();
  const show = useShowFilter()[type];

  const location = useLocation();

  useEffect(() => {
    setFilterState({});
  }, [location.pathname]);

  useEffect(() => {
    if (state && state.type === type) {
      setShowFilter({ [type]: state && state.type === type });
    }
    return () => {
      setShowFilter({ [type]: false });
    };
  }, [type, state]);

  return (
    show &&
    (state?.filter?.filters || []).length > 0 && (
      <div className={`${className} filter`}>
        {(state?.filter?.filters || []).length > 0 && <FilterTitle>Additional Filters</FilterTitle>}
        {(state?.filter?.filters || []).map((filterType, i) => (
          filterType.type === 'factoidOrganization' ?
          <FactoidOrganizationSelect/> :
          filterType.type === 'factoidType' ?
          <FactoidTypeSelect/> :
          filterType.type === 'factoidSource' ?
          <FactoidSourceSelect/> :
          <FilterType
            key={i}
            query={state?.filter?.query}
            filterType={state?.filter?.filterType}
            filter={filterType}
            source={state?.filter?.source || 'db'}
            apiVersion={state?.filter?.apiVersion || ''}
          />
        ))}
      </div>
    )
  );
})`
  background-color: var(--filter-bg);

  padding: var(--base-padding);
  padding-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 6px;

  & {
    & > .filter__title {
      margin-top: 0;
    }
    .filter-type {
      padding: 0 12px;
      .filter__title {
        font-weight: 600;
        line-height: 18px;
      }
    }
    .form-select-input > div {
      background-color: var(--primary);
      border-color: var(--primary);
      &[class$='-control'] {
        height: auto;
      }
    }
    .form-select-input {
      .form-select__single-value {
        color: var(--info-color);
      }
      .form-select__option {
        word-break: break-word;
      }
      .form-select__option:hover,
      .form-select__option--is-focused {
        color: var(--primary);
      }
      .form-select__multi-value__remove {
        color: var(--danger);
      }
      .form-select__input {
        color: var(--blue-reflection) !important;
      }
    }
  }
`;

export default Filter;
