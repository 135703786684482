import { toSnakeCase } from '../../utils/format';

export const includesIgnoreCaseDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  return {
    [field]: {
      includesInsensitive: filterValue[0]
    }
  };
};

export const includesIgnoreCaseEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  const snkField = toSnakeCase(field);

  const values = filterValue[0].split(' ');

  let should: any[] = [];
  values.forEach((value: string) => {
    should.push({
      wildcard: {
        [snkField]: {
          value: `*${value}*`,
          case_insensitive: true
        }
      }
    });
  });

  return {
    must: [
      {
        bool: {
          should,
          minimum_should_match: values.length
        }
      }
    ]
  };
};
