import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CsvProps } from '../../components/DownloadCsv';
import { setAppLoading } from '../../store/app';
import { loadDefaultSort, useFilterQueryCriteriaFromUrl } from '../../utils/filter';

const ORGANIZATIONS_LISTING = gql`
  query OrganizationsListing($filter: JSONObject!, $offset: Int!, $orderBy: [ViewOrganizationsOrderBy!]) {
    organizations: viewOrganizations(first: 150, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        type
        category
        name
        phone
        web
        email
        facebook
        twitter
        linkedin
        address
        state
        county
        city
        zip
        addressId
      }
      totalCount
      totalAggCount
    }
  }
`;

export const useAllOrganizationsQuery = (offset: number, sort: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  useEffect(() => {
    if (sort.length < 1) {
      sort = loadDefaultSort(searchParams, sort, ['NAME_ASC']);
      setSearchParams(searchParams);
    }
  }, [sort, setSearchParams, searchParams]);

  const result = useQuery(ORGANIZATIONS_LISTING, {
    variables: {
      ...queryCriteria,
      offset: offset,
      orderBy: sort
    },
    context: { version: '2' }
  });

  // todo: add result;
  useEffect(() => {
    setAppLoading(true);
    result.refetch(queryCriteria).finally(() => setAppLoading(false));
  }, [queryCriteria]);

  return result;
};

const ORGANIZATIONS_FOR_DOWNLOAD = gql`
  query OrganizationsDownload(
    $first: Int!
    $filter: JSONObject!
    $offset: Int!
    $orderBy: [ViewOrganizationsOrderBy!]
  ) {
    csv: viewOrganizations(first: $first, elasticSearch: $filter, offset: $offset, orderBy: $orderBy) {
      nodes {
        id
        type
        category
        name
        phone
        web
        email
        facebook
        twitter
        linkedin
        address
        state
        county
        city
        zip
      }
    }
  }
`;

export const useCsvPropsForDownload = () => {
  const [searchParams] = useSearchParams();
  const queryCriteria = useFilterQueryCriteriaFromUrl(searchParams, 'elasticSearch');

  const csvProps: CsvProps = {
    query: ORGANIZATIONS_FOR_DOWNLOAD,
    vars: {
      ...queryCriteria,
      offset: 0,
      first: 10000,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  };

  return csvProps;
};

const TOTALS_QUERY = gql`
  query TotalsQuery {
    dashboardTotals: viewOrganizations(elasticSearch: { index: "organizations" }) {
      totalCount
    }
  }
`;

export const useOrganizationsTotalsQuery = () => {
  return useQuery(TOTALS_QUERY, { context: { version: '2' } });
};

const STATES_QUERY = gql`
  query StatesQuery($filter: JSONObject!) {
    states: viewOrganizations(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useOrganizationStatesQuery = () => {
  const filter: any = {
    aggs: {
      results: {
        terms: {
          field: 'state'
        }
      }
    },
    size: 0
  };
  const vars = { variables: { filter: filter }, context: { version: '2' } };

  return useLazyQuery(STATES_QUERY, vars);
};

const COUNTIES_QUERY = gql`
  query CountiesByState($filter: JSONObject!) {
    counties: viewOrganizations(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useOrganizationCounties = () => {
  return useLazyQuery(COUNTIES_QUERY, { context: { version: '2' } });
};

const CITIES_QUERY = gql`
  query CitiesForState($filter: JSONObject!) {
    cities: viewOrganizations(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useOrganizationCities = () => {
  return useLazyQuery(CITIES_QUERY, { context: { version: '2' } });
};

const ORGANIZATION_TYPES_QUERY = gql`
  query OrganizationTypes($filter: JSONObject!) {
    types: viewOrganizations(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useOrganizationTypes = () => {
  return useLazyQuery(ORGANIZATION_TYPES_QUERY, { context: { version: '2' } });
};

const ORGANIZATION_CATEGORIES_QUERY = gql`
  query OrganizationCategories($filter: JSONObject!) {
    categories: viewOrganizations(elasticSearch: $filter) {
      esResult
    }
  }
`;

export const useOrganizationCategories = () => {
  return useLazyQuery(ORGANIZATION_CATEGORIES_QUERY, { context: { version: '2' } });
};

const ORGANIZATIONS_BY_IDS = gql`
  query OrganizationsListing($filter: JSONObject!, $orderBy: [ViewOrganizationsOrderBy!]) {
    organizations: viewOrganizations(elasticSearch: $filter, orderBy: $orderBy) {
      nodes {
        id
        name
      }
    }
  }
`;

export const useOrganizationsByIdsQuery = (ids: string[]) => {

  const filter = {filter: { query: { ids: { values: ids } }, size: 9999 } };

  const result = useLazyQuery(ORGANIZATIONS_BY_IDS, {
    variables: {
      ...filter,
      orderBy: ['NAME_ASC']
    },
    context: { version: '2' }
  });

  // todo: add result;
  // useEffect(() => {
  //   setAppLoading(true);
  //   result.refetch(filter).finally(() => setAppLoading(false));
  // }, [filter]);

  return result;
};
