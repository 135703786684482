import React from 'react';
import {
  Nav as BootstrapNav,
  Navbar as BootstapNavBar,
  NavbarBrandProps,
  NavbarProps,
  NavDropdown as BootstrapNavDropdown,
  NavDropdownProps,
  NavProps
} from 'react-bootstrap';
import { NavbarToggleProps } from 'react-bootstrap/esm/NavbarToggle';
import styled from 'styled-components';
import navBarCollapse from '../../img/menu-collapse.svg';
import navBarExpand from '../../img/menu-expand.svg';
import { useExpandedNav } from '../../store/app';
import Logo from '../Logo';
import { paths } from '../routes/routes';

export const NavBar = styled(({ children, ...rest }: NavbarProps) => {
  return <BootstapNavBar {...rest}>{children}</BootstapNavBar>;
})`
  padding: 0;
  position: relative;
  background-color: var(--nav-bg);
`;
export const NavBarToggle = styled(({ className, ...rest }: NavbarToggleProps) => {
  const expandedNav = useExpandedNav();
  return <img {...rest} className={`${className} navbar-toggler`} src={expandedNav ? navBarCollapse : navBarExpand} />;
})`
  display: block;
  border: none;
  box-shadow: none;
  cursor: pointer;
  position: absolute;

  width: 35px;
`;
export const NavDropdown = styled(({ ...rest }: NavDropdownProps) => {
  return <BootstrapNavDropdown {...rest} />;
})`
  padding: var(--nav-padding);
`;

export const Nav = styled(({ ...rest }: NavProps) => {
  return <BootstrapNav {...rest} />;
})`
  padding: var(--nav-padding);
`;
export const NavItem = styled(({ ...rest }) => {
  return <BootstrapNav.Item {...rest} />;
})``;
export const NavBarBrand = styled(({ className }: NavbarBrandProps) => {
  const expandedNav = useExpandedNav();
  return (
    <BootstapNavBar.Brand className={className} href={paths.dashboard}>
      <Logo small={!expandedNav} />
    </BootstapNavBar.Brand>
  );
})`
  display: inline-block;

  .logo {
    margin-bottom: 0;
  }
`;
