export const phoneExistsDbFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  console.error('Phone exists parameter processor not available for DB query.');
};
export const phoneExistsEsFilter = (field: string, searchParams: URLSearchParams, filterValue: string[]) => {
  if (filterValue[0] === 'false') {
    return {
      must_not: [
        {
          bool: {
            must: [
              {
                wildcard: {
                  phone: '*'
                }
              },
              {
                bool: {
                  must_not: {
                    term: {
                      phone: ""
                    }
                  }
                }
              }
            ]
          }
        },
        {
          nested: {
            path: "factoids",
            query: {
              bool: {
                should: [
                  {
                    bool: {
                      must: [{
                        wildcard: {
                          "factoids.phone.keyword": '*'
                        }
                      },
                      {
                        bool: {
                          must_not: {
                            term: {
                              "factoids.phone.keyword": ''
                            }
                          }
                        }
                      }]
                    }
                  },
                  {
                    bool: {
                      must: [{
                        wildcard: {
                          "factoids.mobile.keyword": '*'
                        }
                      },
                      {
                        bool: {
                          must_not: {
                            term: {
                              "factoids.mobile.keyword": ''
                            }
                          }
                        }
                      }]
                    }
                  }
                ],
                minimum_should_match: 1        
              }
            }
          }
        }
      ]
    };
  } else {
    return {
      should: [
        {
          bool: {
            must: [
              {
                wildcard: {
                  phone: '*'
                }
              },
              {
                bool: {
                  must_not: {
                    term: {
                      phone: ""
                    }
                  }
                }
              }
            ]
          }
        },
        {
          nested: {
            path: "factoids",
            query: {
              bool: {
                should: [
                  {
                    bool: {
                      must: [{
                        wildcard: {
                          "factoids.phone.keyword": '*'
                        }
                      },
                      {
                        bool: {
                          must_not: {
                            term: {
                              "factoids.phone.keyword": ''
                            }
                          }
                        }
                      }]
                    }
                  },
                  {
                    bool: {
                      must: [{
                        wildcard: {
                          "factoids.mobile.keyword": '*'
                        }
                      },
                      {
                        bool: {
                          must_not: {
                            term: {
                              "factoids.mobile.keyword": ''
                            }
                          }
                        }
                      }]
                    }
                  }
                ],
                minimum_should_match: 1        
              }
            }
          }
        }
      ],
      minimum_should_match: 1
    };
  }
};

