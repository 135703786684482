import { faBookOpen, faComments, faShare, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { usePeopleTotalsQuery } from '../../gql/people';
import { MESSAGING_ROLE } from '../../utils/auth';
import { routes } from '../routes/routes';
import { NavItem, SubMenu } from './NavigationConfig';

const MessagingNavigationConfig: (NavItem | SubMenu)[] = [
  {
    roles: [MESSAGING_ROLE],
    filterType: 'people',
    title: 'Groups',
    route: routes.messagingGroups,
    icon: faUserGroup,
    queryHook: usePeopleTotalsQuery
  },
  {
    roles: [MESSAGING_ROLE],
    filterType: 'campaigns',
    title: 'Campaigns',
    route: routes.messagingCampaigns,
    icon: faComments
  },
  {
    roles: [MESSAGING_ROLE],
    filterType: 'share-list',
    title: 'Sharing',
    route: routes.messagingShareList,
    icon: faShare
  },
  {
    roles: [MESSAGING_ROLE],
    filterType: 'messaging-stories',
    title: 'Stories',
    route: routes.messagingStories,
    icon: faBookOpen
  }
];

export default MessagingNavigationConfig;
