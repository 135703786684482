import dayjs from 'dayjs';

export const GENERAL = '453c3d45-aa7b-47d2-a2cf-7dac358b2e8f';
export const PRIMARY = '416b4b91-abf5-4b9c-a25f-6522e8ab5633';
export const PRIMARY_ALT = '46d74977-8fb8-4bb5-bd74-d8212eb1730e';
export const VOTED = 'voted';
export const REPUBLICAN = 'rep';
export const DEMOCRATIC = 'dem';

export const getLast4Gop = (rowData: any) => {
  return getLast4(rowData, PRIMARY, REPUBLICAN) + getLast4(rowData, PRIMARY_ALT, REPUBLICAN);
};

export const getLast4Dem = (rowData: any) => {
  return getLast4(rowData, PRIMARY, DEMOCRATIC) + getLast4(rowData, PRIMARY_ALT, DEMOCRATIC);
};

export const getLast4Ge = (rowData: any) => {
  return getLast4(rowData, GENERAL, VOTED);
};

const getLast4 = (rowData: any, electionType: string, vote: string) => {
  if (!rowData.voterHistory) {
    return 0;
  }



  let total = 0;
  let year = getLatestElectionYear();

  for (let i = 0; i < 4; i++) {
    for (let vh in rowData.voterHistory) {
      const h = rowData.voterHistory[vh];
      if (h.election_type_id === electionType && h.vote_result.includes(vote) && h.year == year) {
        total++;
      }
    }

    year = year - 2;
  }
  return total;
};

// if the year is even, go back to the previous election
// else if it's more than 1/2 way thru the current year, look back 1 year
// april 2024 -- won't have the results of 2024 so use 2022 as the start year
// april 2023 -- probably won't have the results of 2022 so use 2020 as the start year
// july 2023 -- should have the results of 2022 so use 2022 as the start year
export const getLatestElectionYear = () => {
  const startYear = dayjs().get('year');
  return startYear % 2 === 0 ? startYear - 2 : dayjs().get('month') > 6 ? startYear - 1 : startYear - 3;
};
