import { useCallback } from 'react';
import { Modal as BsModal, ModalDialogProps } from 'react-bootstrap';
import styled from 'styled-components';
import { setConfirmModal, useConfirmModal } from '../../store/modal';
import Button from '../Button';

import { isNil } from 'lodash';
import React from 'react';

export interface ModalProps extends ModalDialogProps {
  open?: boolean;
  handleClose: () => void;
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  closeButton?: boolean;
}

const Modal = styled(({ className, open, handleClose, buttons, title, children, closeButton = true, ...rest }: ModalProps) => {
  return open ? (
    <BsModal
      onClick={(e: any) => e.stopPropagation()}
      show={open}
      onHide={handleClose}
      {...rest}
      className={`rl-modal ${className}`}
    >
      {title && (
        <BsModal.Header closeButton={closeButton}>
          <BsModal.Title>{title}</BsModal.Title>
        </BsModal.Header>
      )}

      <BsModal.Body>{children}</BsModal.Body>

      {buttons && <BsModal.Footer>{buttons}</BsModal.Footer>}
    </BsModal>
  ) : null;
})`
  .modal-header {
    background-color: var(--border-color);
  }
`;

const ConfirmModal = () => {
  const { open, message, onConfirm, onCancel, title, confirmLabel, cancelLabel } = useConfirmModal();

  const handleClose = useCallback(() => {
    setConfirmModal({ open: false, message: '', onConfirm: () => null });
  }, []);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      centered={true}
      title={title || 'Confirm'}
      buttons={
        <>
          <Button
            variant={'white'}
            onClick={() => {
              if (!isNil(onCancel)) {
                onCancel!();
              }
              handleClose();
            }}
          >
            {cancelLabel || 'Cancel'}
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              handleClose();
            }}
          >
            {confirmLabel || 'Confirm'}
          </Button>
        </>
      }
    >
      {message}
    </Modal>
  );
};

export default Modal;
export { ConfirmModal };
